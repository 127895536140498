

body {
  font-family: "Helvetica", Arial, sans-serif;
}

a {
    color:var(--main-color);
    
}

a:hover {
    color:rgb(0, 39, 196);
    cursor: pointer;
}

.app-content {
    padding-top: 120px;
    padding-bottom: 2rem;
    width: 100%;
    margin: 0 auto;
}
/* page */
.page {
  width: 100%;
  margin:auto;
  padding:1rem;
}

/* container */

.box-style1 {
    padding:1rem;
    border : var(--main-color) 1px solid;
    border-radius: 10px;
    width: 70%;
}

.box-style1-fit {
    padding:1rem;
    border : var(--main-color) 1px solid;
    border-radius: 10px;
    width: fit-content;
    height: fit-content;
}


.box-style4 {
    padding:1rem;
    background-color: #AC86F740;
    border-radius: 10px;
}

.title-style1 {
    font-size: 24px;
    text-align: center;
}

.title-style2 {
    color: var(--main-color);
    font-weight: bold;
}

.text-color {
    color : var(--main-color);
}

.text-bold {
    font-weight: bold;
}

.font-size-large-title {
    font-size :1.8rem;
}

.font-size-small-title {
    font-size :1.5rem;
}

.font-size-large {
    font-size :1.2rem;
}

.font-size-medium {
    font-size :1rem;
}

.font-size-small {
    font-size :14px;
}

.font-size-xsmall {
    font-size :12px;
}

.text-grey {
    color: grey;
}

.text-red {
  color: #ff1d58;
}

.text-green {
  color: #3d7c47;
}

.bold {
    font-weight: bold;
}

/* button */

.icon-button {
  width: fit-content;
  padding: 0 10px;
  aspect-ratio: 1;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

}

.button {
    padding: 5px 15px;
    font-size: 16px;
    cursor: pointer;
    height: fit-content;
    min-width: 60px;
    width: fit-content;
    border-radius: 20px;
}

.button-disabled {
  background-color: #d3d3d3; /* Gray color */
  color: #fff;           /* Darker gray */
  cursor: not-allowed;      /* Disabled cursor */
  pointer-events: none;     /* Prevent any click events */
}

.button:focus {
    outline: none;
}

.button-style1 {
    background-color: var(--main-color);
    color: #fff;
}
.button-style1:hover {
    background-color: var(--main-color-hover-dark);
}

.button-style2 {
    background-color: white;
    color: var(--main-color);
    border: var(--main-color) solid 1px;
   
}

.button-style2:hover {
    background-color: rgb(236, 226, 255);
}

.button-style3 {
    background-color: white;
    color: var(--main-color);
}

.button-style3:hover {
    background-color: rgb(236, 226, 255);
}
.button-style2-no-border {
    background-color: white;
    color: var(--main-color);
}

.button-style2-no-border:hover {
    background-color: rgb(236, 226, 255);
}

/* text */
.text-blue {
    color:blue;
}

.text-gray {
  color:gray;
}

.fit-content {
    width: fit-content;
}

/* flex */
.flex-column-style1 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
}

.gap-half {
    gap: 0.5rem;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.justify-center {
    justify-content: center;
}

.justify-start {
    justify-content: start;
}

.align-center {
    align-items: center;
}

.text-center {
  text-align: center;
}

.padding-14 {
  padding: 14px;
}

.width-300 {
  width: 300px;
}

/* link */
.link-style1 {
    color : var(--main-color);
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    height: fit-content;
}

.link-style1:hover {
    background-color : var(--main-color-hover-light);
    border-radius: 10px;
}

/* form */
input[type='text'] , textarea {
  padding: 0.3rem;
  border-radius: 5px;
  border : var(--main-color) 1px solid;
}



/* badge */
.badge-style1 {
  font-size: 0.8rem;
  background-color: var(--main-color);
  padding: 0 0.3rem;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  width: fit-content;
}


.modal-style1 {
  height: fit-content;
  max-height: 80vh;
  width: fit-content;
  min-width: 600px;
  background-color: white;
  position: fixed;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--main-color);
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
}
.modal-style1:focus{
    outline: unset;
}
.m-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--main-color);
  font-size: 1.5rem;

}

.m-footer {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.modal-style1>div {
  padding: 0.5rem;
}
.m-body {
  border-top: solid rgb(208, 208, 208) 1px;
}

.btn-primary {
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
    border-radius: 5px;
}

.btn-secondary {
    border-radius: 5px;
}

.btn-link-outline {
    color: var(--main-color);
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-radius: 5px;
}


.btn-link-outline:hover {
    color: (--main-color-hover-dark);
    border: 1px solid var(--main-color);
}

.btn-link {
    color: var(--main-color);
}

.div-link {
  color: var(--main-color);
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding-left: 10px;
}



 