.blog-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  margin: 5px;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  padding-bottom: 36px;
}

.blog h6 {
  margin-top: 0;
}

.blog p {
  margin-top: 5px;
}

.blog a {
  text-decoration: none;
  color: #0073e6;
}

.blog a:hover {
  text-decoration: underline;
}

.blog-card .blog-tag-date {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  color: var(--main-color);
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  /* border: 1px solid var(--main-color-hover-light); */
}

.blog-card .blog-tag-l1 {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: white;
  color: var(--main-color);
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  /* border: 1px solid var(--main-color-hover-light); */
}

