.jobdashboard-page-content {
    display: flex;
    gap: 2rem;
}

.jobdashboard-page-content .input-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.search-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.joblist-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.jobdashboard-page input[type='text'] {
    width: 300px;
}
