.navigation-section {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    padding: 0.5rem;
    z-index: 10;
    width: 100vw;
}

.navigation-common {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.navigation-student {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.navigation-section .logo {
    height : 50px;
}

.navigation-section .desktop-only {
    display: block;
}

.navigation-section .mobile-only {
    display: none;
}

@media (max-width: 768px) {
    .navigation-section .desktop-only {
        display: none;
    }

    .navigation-section .mobile-only {
        display: block;
    }
}