p {
    margin : 0;
}
.note-card {
    border-top: dashed 2px var(--main-color);
    padding: 2rem 1rem;

}
.note-card-title {
    display: grid;
    grid-template-columns: auto auto 100px;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
    grid-template-areas: 
    "url tag edit";
}

.note-tag {
    border : solid 1px var(--main-color);
    padding: 0.2rem 1rem;
    border-radius: 10px;
    color: var(--main-color);
    font-size : 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: fit-content;
}

.url {
    font-style: italic;
    color: var(--main-color);
    word-break: break-all;
}

.remove-btn {
    cursor: pointer;
}

.remove-btn:hover {
    color: red;
}

.tag-section {
    grid-area: tag;
    display: flex;
    justify-content: flex-end;
    gap:0.5rem;
    flex-wrap: wrap;
}

.url-section {
    grid-area: url;
}

.edit-section {
    grid-area: edit;
    display: flex;
    justify-content: flex-end;
}

.edit-section  .desktop-only {
    display: inline-block;
    margin-left: 0.5rem;
}

.content-display {
    max-width: 100%;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word; 
    white-space: normal; 
    overflow: hidden; 
}
@media (max-width: 768px) {
    .note-card {
        padding: 0.5rem;
    }

    .note-card-title {
        grid-template-columns: auto 100px;
        grid-template-areas: 
        "url edit"
        "tag tag";
    }
    
    .tag-section {
        justify-content: flex-start;
    }

    .desktop-only {
        display: none;
    }
}