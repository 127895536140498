.user-profile-page {
  width: 100%;
}

.user-profile-content {
  width:100%;
  margin: auto;
  display: grid;
  gap: 2rem;
  grid-template-columns: auto 300px;
}

.user-profile-page h2 {
  color: rgb(68, 158, 255);
}

.skills-flex {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
}

.left-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-grow: 1;
  max-width: 100%;
  /* overflow: scroll; */
}

.right-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.photo-score {
  display: flex;
  gap: 2rem;
}

.photo-score>div  {
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:0.5rem;
}
.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: fill;
  overflow: hidden;
}

.profile-image>img {
  width: 100%;
  height: auto;
}
.score-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: normal; 
  }

  .user-info-flex {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .right-section {
     display: flex;
     flex-direction: column;
     gap: 2rem;
  }

  .right-section>div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
 }

 .achievement-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
 }

 .achievements {
    display: flex;
    flex-direction: column;
    white-space: pre-line;
    gap: 1rem;
    /* overflow: scroll; */
    width: 100%;
 }

 .modal-button-group {
  display: flex;
  gap : 1rem;
}
.edit-info-modal>div{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 100%;
}

.edit-info-modal  input {
  width: 100%;
  margin-top:5px;
}
.edit-info-modal  textarea {
  width: 100%;
  height: 150px;
  margin-top:5px;
}

.edit-info-modal .photo-section {
  display: flex;
  flex-direction: column;
  gap : 0.5rem;
  justify-content: center;
  align-items: center;
}

.edit-info-modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  color: rgb(42, 124, 255);
}

.edit-info-modal {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  column-gap: 2rem;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1000 !important;
}

@media (max-width: 768px) {
  .edit-info-modal {
    grid-template-columns: repeat(1, 1fr); 
    column-gap: 1rem;
  }

  .user-profile-content {
    gap: 1rem;
    grid-template-columns: auto ;
  }

} 

