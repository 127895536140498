.create-profile-form {
    display: flex;
    flex-direction: column;
    gap:2rem;
}

.create-profile-form .title {
    border-bottom: solid var(--main-color) 1px;
}

.personalInfo-section {
    display: flex;
    gap:2rem;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.profile-image {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 50% 50%;
    overflow: hidden;
}

.profile-image>img {
    width: 100%;
}

.input-section {
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.input-section>div {
    display: flex;
    gap:0.5rem;
}

.input-section>div>label {
    width: 150px;
}

.input-section input[type='text'] {
    width : 100%;
    max-width: 300px;
}

.multi-select{
    width : 100%;
    max-width: 600px;
}
.multi-select .css-13cymwt-control {
    padding: 0.3rem;
    border-radius: 5px;
    border : var(--main-color) 1px solid;
}

.multi-select .css-1p3m7a8-multiValue {
    background-color: #FFE5DD;
    color: var(--main-color);
}

.achievement-input {
    min-height: 150px;
}
.input-section textarea {
    width: 100%;
    max-width: 600px;

}

.professional-section {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.professional-section>div {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    flex:1;
}

.create-profile-form  .submit-button {
    align-self: center;
}

.profile-image img {
  width: 100%;
  height: auto;
}

.photo-upload-label {
  cursor: pointer;
  color: var(--main-color);
  text-decoration: underline;
}

.button-style-profile {
  background-color: rgb(236, 226, 255);
  padding: 5px 15px;
  font-size: 16px;
  height: fit-content;
  min-width: 60px;
  width: fit-content;
  border-radius: 20px;
  /* background-color: white; */
  color: var(--main-color);
}