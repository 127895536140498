.user-form {
    padding: 2rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-form input{
    width: 300px;
    border-radius: 5px;
    border: var(--main-color) 1px  solid;
    padding: 0.5rem;
}

.user-form input[type='checkbox']{
    width: fit-content;
}

.user-form textarea{
    width: 600px;
    border-radius: 5px;
    border: var(--main-color) 1px  solid;
    padding: 0.5rem;
}

@media screen and (max-width: 750px) { 
    .user-form textarea{
        width: 100%;
    }
}

/* Mobile Devices*/
@media screen and (max-width: 480px) {
    .user-form textarea{
        width: 100%;
    }
}

