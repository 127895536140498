.userhomepage-page {
    width: 100%;
    padding: 1rem 2rem;
}

.userhomepage-content {
    display: grid;
    grid-template-columns: 65% auto;
    gap:0.5rem;
}

.userhomepage-right {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.project-filter {
    display: flex;
    gap:2rem;
}

.project-item-group {
    display: flex;
    gap: 0.5rem;
    max-height: 800px;
    flex-direction: column;
    overflow: scroll;
}

.project-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.answer-user-info {
    display: grid;
    grid-template-columns: 200px auto;
}

.answer-items {
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.userhomepage-search-input-group {
    height: 30px;
    position: relative;
    width: 300px;
    
}
.userhomepage-search-button {
    position: absolute;
    right: 0;
    top:0;
    height: 30px;
    padding:5px 10px;
    font-size: 12px;
    padding:auto;
    cursor: pointer;
}

.userhomepage-search-button:hover {
    color: blue;
}

.userhomepage-search-input{
    width: 100%;
}
.userhomepage-search-input>input {
    width: 100%;
    padding-right:50px;
}

.courses-item-flex , .profile-item-flex {
    display: flex;
}

.coursename {
    width: 250px;
}

.profile-name {
    width: 250px;
}

.blog-info{
    display: flex;
}

.blog-length {
    width: 120px;
}

.create-new-button {
    background-color: rgb(110, 202, 255);
    padding: 5px 10px;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
}

.create-new-button:hover {
    background-color: rgb(63, 172, 245);
}

.new-solution-modal-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  
    background-color: white;
}
.new-solution-modal-content input{
    width: 100%;
    margin-top: 5px;
}

.new-solution-modal-content textarea{
    width: 100%;
    height: 300px;
    margin-top: 5px;
}

.blog-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-list {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1rem;
    height: 100%;
    width: 100%;
    max-width: 1200px;
}

@media (max-width: 768px) { 
    .blog-list {
        grid-template-columns: repeat(2,1fr);
    }
}
