.job-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.section1 {
    display: flex;
    gap: 1rem;
}

.section3 {
    display: flex;
    gap: 1rem;
}

.employer-logo {
    height: 50px;
    aspect-ratio: 1;
    object-fit: contain;
}

.employer-logo>img {
    width: 100%;
}

.section1 .left-div{
    width: 400px;
}

.apply-button {
    align-self: flex-end;
}

.job-application-modal textarea {
    width: 100%;
    height: 150px;
}
.job-application-modal .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.job-application-modal .upload-resume {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
