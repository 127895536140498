.create-profile-form {
    display: flex;
    flex-direction: column;
    gap:2rem;
}

.create-profile-form .title {
    border-bottom: solid var(--main-color) 1px;
}


.input-section {
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.input-section>div {
    display: flex;
    gap:0.5rem;
}

.input-section>div>label {
    width: 150px;
}

.input-section input[type='text'] {
    width : 100%;
    max-width: 300px;
}

.multi-select{
    width : 100%;
    max-width: 600px;
    justify-content: flex-start;
}
.multi-select .css-13cymwt-control {
    padding: 0.3rem;
    border-radius: 5px;
    border : var(--main-color) 1px solid;
}

.multi-select .css-1p3m7a8-multiValue {
    background-color: #FFE5DD;
    color: var(--main-color);
}

/* React Select control customization */
.select__control {
    display: flex;
    justify-content: flex-start; /* Align selected items to the start */
    align-items: center;         /* Vertically center items */
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;                 /* Ensure the control spans full width */
  }

  /* Multi-value customization: Proper spacing between selected labels */
  .select__multi-value {
    margin: 0 0.25rem 0 0; /* Add space between labels */
  }

  /* Adjust the dropdown menu */
  .select__menu {
    display: block;               /* Ensure proper dropdown alignment */
    text-align: left;             /* Align dropdown options to the left */
  }

  /* Ensure placeholder aligns to the start */
  .select__placeholder {
    text-align: left;             /* Align placeholder to the left */
    width: 100%;                  /* Ensure it uses full width */
  }

.input-section textarea {
    width: 100%;
    max-width: 600px;
    min-width: 250px;
}


.create-profile-form  .submit-button {
    align-self: center;
}

.logo-image {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.logo-image img {
    width: 100%;
}
