
.search-nav {
  margin: 20px;
}

.search {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  border: 1px solid #ddd;
  padding: 5px 10px;
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: #0073e6;
  color: white;
  z-index: 0;
}